import React, { useEffect, useState } from "react";
import { Box, Button, TextField, Typography, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { auth } from "../../firebase";
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";
import {
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import GoogleIcon from "@mui/icons-material/Google";
import { useDispatch, useSelector } from 'react-redux';
import { setUserEmail, setUserName, setUserInstitution, setUserActivated } from "../../store/reducers/user";
import apis from "../../api";
import ReCAPTCHA from "react-google-recaptcha";
import { sendEmailVerification } from "firebase/auth";

export const WelcomePage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userEmail = useSelector(state => state.user.email);

  const [isLogin, setIsLogin] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const [lastName, setLastName] = useState("");
  const [institution, setInstitution] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [captchaToken, setCaptchaToken] = useState(null);

  const [confirmPassword, setConfirmPassword] = useState("");

  const googleProvider = new GoogleAuthProvider();

  const handleCaptcha = (token) => {
    setCaptchaToken(token);
  };

  const handleAuth = () => {
    if (!captchaToken) {
      alert("Please verify that you are not a robot.");
      return;
    }

    if (isLogin) {
      signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          const user = userCredential.user;
          // if (!user.emailVerified) {
          //   alert("Please verify your email before logging in. We have automatically resent the verification email.");
          //   resendVerificationEmail();
          //   return;
          // }
          console.log("User logged in:", user);
          dispatch(setUserEmail(email));
          apis.get_user_info({ email: email, username: "", lastname:"", institution: "", city: "", state: "", country: "" })
            .then((response) => {
              dispatch(setUserName(response.data.username));
              dispatch(setUserInstitution(response.data.institution));
            })
            .catch((error) => {
              console.error("Error:", error.message);
            });
          navigate("/");
        })
        .catch((error) => {
          console.error("Error:", error.message);
          if (
            error.code === "auth/invalid-email" ||
            error.code === "auth/wrong-password" ||
            error.code === "auth/invalid-login-credentials"
          ) {
            alert("Invalid login credentials. Please try again.");
          }
        });
    }
    else {

      if (password.length < 6) {
        alert("Password should be at least 6 characters.");
        return;
      }

      if (password !== confirmPassword) {
        alert("Password and confirm password do not match. Please try again.");
        return;
      }

      if (!email || !password || !username || !lastName || !institution || !city || !state || !country) {
        alert("Please fill in all fields.");
        return;
      }
      createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        console.log("User signed up:", user);
        // sendEmailVerification(user)
        //   .then(() => {
        //     alert("Account activation email sent. Please check your inbox to verify your account.");
        //   })
        //   .catch((error) => {
        //     console.error("Error sending email verification:", error.message);
        //     alert("Error sending verification email. Please try again.");
        //   });

          // Store user information
          // dispatch(setUserEmail(email));
          // dispatch(setUserName(username));
          // dispatch(setUserInstitution(institution));
          apis.register({
            email: email,
            username: username,
            lastname: lastName,
            institution: institution,
            city: city,
            state: state,
            country: country
          });
          alert("Please check your email to verify your account to continue. Please re-login after verification. Thank you.");
          // navigate("/");
          window.location.reload();
        })
        .catch((error) => {
          console.error("Error:", error.message);
          if (error.code === "auth/email-already-in-use") {
            alert("Email already in use. Please Login.");
          } else if (error.code === "auth/weak-password") {
            alert("Password is too weak. Please try again.");
          }
        });

    }
  };

  const resendVerificationEmail = () => {
    if (!auth.currentUser) {
      alert("No user is logged in.");
      return;
    }
    sendEmailVerification(auth.currentUser)
      .then(() => {
        // alert("Verification email resent. Please check your inbox.");
      })
      .catch((error) => {
        console.error("Error resending verification email:", error.message);
        alert("Error resending verification email. You email could be invalid. Please try again.");
      });
  };

  const handlePasswordReset = (resetEmail) => {
    if (!resetEmail) {
      alert("Please enter your email address to reset your password.");
      return;
    }
    sendPasswordResetEmail(auth, resetEmail)
      .then(() => {
        alert("Password reset email sent. Please check your inbox.");
      })
      .catch((error) => {
        console.error("Error:", error.message);
        alert("Error:", error.message);
        if (error.code === "auth/invalid-email") {
          alert("Invalid email address. Please try again.");
          return;
        }
      });
  };

  const getLocationDataFromCoordinates = (lat, lng) => {
    const url = `https://nominatim.openstreetmap.org/reverse?lat=${lat}&lon=${lng}&format=json`;
    return fetch(url)
      .then(response => response.json())
      .then(data => {
        if (data && data.address) {
          const city = data.address.city || data.address.town || data.address.village || null;
          const state = data.address.state || null;
          const country = data.address.country || null;
          return { city, state, country };
        }
        console.error("Reverse geocoding error:", data);
        return null;
      })
      .catch(error => {
        console.error("Reverse geocoding error:", error);
        return null;
      });
  };
  

  const handleGoogleLogin = () => {
    signInWithPopup(auth, googleProvider)
      .then((result) => {
        const user = result.user;
        let names = user.displayName.split(" ");
        let firstName = names.length > 1 ? names[1] : names[0];
        let lastName = names.length > 1 ? names[0] : "";
  
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            async (position) => {
              const { latitude, longitude } = position.coords;
              console.log("Latitude:", latitude, "Longitude:", longitude);
  
              const locationData = await getLocationDataFromCoordinates(latitude, longitude);
              const city = locationData ? locationData.city : null;
              const state = locationData ? locationData.state : null;
              const country = locationData ? locationData.country : null;
  
              apis.register({
                email: user.email,
                username: firstName,
                lastname: lastName,
                institution: "",
                city: city || "",
                state: state || "",
                country: country || "",
                latitude,
                longitude,
              });
  
              dispatch(setUserEmail(user.email));
              dispatch(setUserActivated(true));
              dispatch(setUserName(user.displayName));
              navigate("/");
            },
            (error) => {
              console.error("Error retrieving location:", error);
              apis.register({
                email: user.email,
                username: firstName,
                lastname: lastName,
                institution: "",
                city: "",
                state: "",
                country: "",
              });
              dispatch(setUserEmail(user.email));
              dispatch(setUserActivated(true));
              dispatch(setUserName(user.displayName));
              navigate("/");
            }
          );
        } else {
          console.log("Geolocation is not supported by this browser.");
          // Proceed without location info
          apis.register({
            email: user.email,
            username: firstName,
            lastname: lastName,
            institution: "",
            city: "",
            state: "",
            country: "",
          });
          dispatch(setUserEmail(user.email));
          dispatch(setUserActivated(true));
          dispatch(setUserName(user.displayName));
          navigate("/");
        }
      })
      .catch((error) => {
        console.error("Error:", error.message);
        alert("Error:", error.message);
      });
  };
  
  
  
  

  // const handleGoogleLogin = () => {
  //   signInWithPopup(auth, googleProvider)
  //     .then((result) => {
  //       console.log(result)
  //       const credential = GoogleAuthProvider.credentialFromResult(result);
  //       const user = result.user;
  //       let displayName = user.displayName;
  //       let names = displayName.split(" ");
  //       let firstName = "";
  //       let lastName = "";
  //       if (names.length > 1) {
  //         firstName = names[1];
  //         lastName = names[0];
  //       } else {
  //         firstName = names[0];
  //         lastName = "";
  //       }
  //       apis.register({ email: user.email, username: firstName, lastname: lastName, institution: "", city: "", state: "", country: "" });
  //       dispatch(setUserEmail(user.email));
  //       dispatch(setUserActivated(true));
  //       dispatch(setUserName(user.displayName));
  //       navigate("/");

  //     })
  //     .catch((error) => {
  //       console.error("Error:", error.message);
  //       alert("Error:", error.message);
  //     });
  // };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
        // height: "100vh",
        width: "100%",
        height: "100%",
        // backgroundColor: "#f9f9f9",
        // padding: "20px",
      }}
    >
      <Typography
        variant="h4"
        component="h1"
        sx={{ mt: 10, mb: 4, color: "#333", fontWeight: "bold", textAlign: "center" }}
      >
        Welcome to PennPRS
      </Typography>

      <Button
        startIcon={<GoogleIcon color="primary" />}
        onClick={handleGoogleLogin}
        sx={{
          width: "550px",
          height: "48px",
          textTransform: "none",
          backgroundColor: "#ffffff",
          color: "#757575",
          ":hover": {
            backgroundColor: "#f5f5f5",
          },
          border: "1px solid #ddd",
          borderRadius: "4px",
          fontWeight: "bold",
          fontSize: "1rem",
        }}
      >
        <Typography variant="body1" sx={{ color: "#333" }}>
          Continue with Google Account
        </Typography>
      </Button>

      <Typography
        variant="body2"
        sx={{ mt: 3, mb: 3, textAlign: "center", color: "#aaa" }}
      >
        — Or continue with —
      </Typography>


      <Box
        sx={{
          width: "100%",
          maxWidth: "500px",
          padding: "24px",
          backgroundColor: "#ffffff",
          borderRadius: "8px",
          border: "1px solid #ddd",
          mb: 3
        }}
      >

        <Typography
          variant="body1"
          sx={{ mb: 2, color: "#555", textAlign: "center" }}
        >
          {isLogin ? "Login with Email" : "New User Registration"}
        </Typography>

        {!isLogin && (
          <Grid container spacing={4} sx={{ mb: 2 }}>
            <Grid item xs={12} sm={6}>
              <TextField
                label="First Name"
                variant="outlined"
                required
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Last Name"
                variant="outlined"
                required
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Organization"
                variant="outlined"
                value={institution}
                required
                onChange={(e) => setInstitution(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="City"
                variant="outlined"
                value={city}
                required
                onChange={(e) => setCity(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="State"
                variant="outlined"
                value={state}
                required
                onChange={(e) => setState(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Country"
                variant="outlined"
                value={country}
                required
                onChange={(e) => setCountry(e.target.value)}
                fullWidth
              />
            </Grid>
          </Grid>
        )}

        <TextField
          label="Email"
          variant="outlined"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          margin="normal"
          fullWidth
          required
          sx={{ mb: 2 }}
        />
        <TextField
          label="Password"
          type="password"
          variant="outlined"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          margin="normal"
          fullWidth
          required
          sx={{ mb: 2 }}
        />
        {!isLogin && (
          <TextField
            label="Confirm Password"
            type="password"
            variant="outlined"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            margin="normal"
            fullWidth
            required
            sx={{ mb: 2 }}
          />)}
        <Button
          color="secondary"
          sx={{
            width: "100%",
            textTransform: "none",
            fontWeight: "bold",
            fontSize: "0.9rem",
            mb: 1,
          }}
          onClick={() => setIsLogin((prev) => !prev)}
        >
          {isLogin ? "New User? Sign up here" : "Already have an account? Login here"}
        </Button>

        {isLogin && (
          <Button
            color="secondary"
            sx={{
              textTransform: "none",
              fontWeight: "bold",
              fontSize: "0.9rem",
              width: "100%",
            }}
            onClick={() => {
              const resetEmail = prompt("Enter your email to reset your password:");
              handlePasswordReset(resetEmail);
            }}
          >
            Forgot Password?
          </Button>
        )}


        <Box sx={{ m: 2, display: "flex", justifyContent: "center", alignItems: "center" }}>
          <ReCAPTCHA sitekey="6Le4P7oqAAAAAFM64r5w6IC_fCRmlBnYnPQqcNdc" onChange={handleCaptcha} />
        </Box>
        <Button
          variant="contained"
          onClick={handleAuth}
          sx={{
            width: "100%",
            height: "48px",
            backgroundColor: "#003366",
            ":hover": {
              backgroundColor: "#002244",
            },
            borderRadius: "4px",
            textTransform: "none",
            fontWeight: "bold",
            fontSize: "1rem",
            mb: 2,
          }}
        >
          {isLogin ? "Login" : "Sign Up"}
        </Button>
      </Box>
    </Box>


  );
};
