import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import MethodCard from "../../component/MethodCard";
import Tooltip from "@mui/material/Tooltip";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import apis from "../../api";
import { setUserName } from "../../store/reducers/user";
import { useDispatch } from "react-redux";
import { List, ListItem, ListItemText } from '@mui/material';
import VerifiedIcon from '@mui/icons-material/Verified';
import { motion } from 'framer-motion';

export const HomePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [more, setMore] = useState(false);
  const userEmail = useSelector((state) => state.user.email);
  const userName = useSelector((state) => state.user.username);

  const [isMobile, setIsMobile] = useState(false);

  const items = [
    <Box sx={{ alignItems: "center", display: "flex", flexDirection: "row" }}>
      <VerifiedIcon sx={{ color: "#0d47a1", marginRight: 1 }} />
      Efficient
      <Typography component="body1" sx={{ fontWeight: "bold", color: "#0d47a1", fontSize: 18, mr: 1, ml: 1 }}>
        no-coding
      </Typography>
      PRS model training experience
    </Box>,
    <Box sx={{ alignItems: "center", display: "flex", flexDirection: "row" }}>
      <VerifiedIcon sx={{ color: "#0d47a1", marginRight: 1 }} />
      Currently enables online training for
      <Typography component="body1" sx={{ fontWeight: "bold", color: "#0d47a1", fontSize: 18, mr: 1, ml: 1 }}>
        7 single- and multi-ancestry
      </Typography>
      PRS methods
    </Box>,
    <Box sx={{ alignItems: "center", display: "flex", flexDirection: "row" }}>
      <VerifiedIcon sx={{ color: "#0d47a1", marginRight: 1 }} />
      An offline pipeline supporting
      <Typography component="body1" sx={{ fontWeight: "bold", color: "#0d47a1", fontSize: 18, mr: 1, ml: 1 }}>
        10
      </Typography>
      methods for PRS training without requiring individual-level data
    </Box>,
    <Box sx={{ alignItems: "center", display: "flex", flexDirection: "row" }}>
      <VerifiedIcon sx={{ color: "#0d47a1", marginRight: 1 }} />
      Over&nbsp;
      <Typography component="body1" sx={{ fontWeight: "bold", color: "#0d47a1", fontSize: 18, mr: 1, ml: 0 }}>
        27,000
      </Typography>
      queryable datasets from the GWAS Catalog and &nbsp;
      <Typography component="body1" sx={{ fontWeight: "bold", color: "#0d47a1", fontSize: 18, mr: 1, ml: 0 }}>
        2,400
      </Typography>
      from the FinnGen
    </Box>,
    <Box sx={{ alignItems: "center", display: "flex", flexDirection: "row" }}>
      <VerifiedIcon sx={{ color: "#0d47a1", marginRight: 1, color: "#0d47a1" }} />
      Publicly available PRS weights for over
      <Typography component="body1" sx={{ fontWeight: "bold", color: "#0d47a1", fontSize: 18, mr: 1, ml: 1 }}>
        8,000
      </Typography>
      phenotypes across
      <Typography component="body1" sx={{ fontWeight: "bold", color: "#0d47a1", fontSize: 18, mr: 1, ml: 1 }}>
        5
      </Typography>
      superpopulations and
      <Typography component="body1" sx={{ fontWeight: "bold", color: "#0d47a1", fontSize: 18, mr: 1, ml: 1 }}>
        7
      </Typography>
      studies
    </Box>,
  ];


  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.matchMedia("(max-height: 868px)").matches);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const scrollToContent = () => {
    setMore(true);
    const distanceToBottom =
      document.body.scrollHeight - window.scrollY - window.innerHeight;
    window.scrollBy({ top: distanceToBottom, behavior: "smooth" });
  };

  const FAQ = "https://groups.google.com/g/pennprs";

  const handleRedirectFQA = () => {
    window.open(FAQ, "_blank");
  }

  const Tutorial = "https://pennprs.gitbook.io/pennprs";
  const handleRedirectTutorial = () => {
    window.open(Tutorial, "_blank");
  }

  useEffect(() => {
    const handleScroll = () => {
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight - 50) {
        setMore(true);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    const data = { email: userEmail, username: "", lastname: "", institution: "", city: "", state: "", country: "" };
    apis.get_user_info(data).then((response) => {
      dispatch(setUserName(response.data.username));
    }).catch((error) => {
      console.error("Error:", error.message);
    });
  }, []);

  const handleRedirectSA = () => {
    if (userEmail === null) {
      navigate("/login");
    } else {
      navigate("/sa");
    }
  };

  const handleRedirectMA = () => {
    if (userEmail === null) {
      navigate("/login");
    } else {
      navigate("/ma");
    }
  };

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          minHeight: "100vh",
          width: "100%",
          // background: "linear-gradient(180deg, #e3f2fd 0%, #90caf9 50%, #e0e0e0 100%)",
          background: "#F8F8F8",
          padding: "2rem 0",
          overflowX: "hidden",
        }}
      >
        {/* Header Section */}
        <Typography
          variant="h2"
          component="h2"
          sx={{
            mt: 8,
            fontWeight: "bold",
            cursor: 'pointer',
            display: 'inline-block',
            fontFamily: 'Chalkboard, sans-serif',
            '@media (min-width: 768px)': {
              fontSize: '3rem',
            },
            '@media (min-width: 1024px)': {
              fontSize: '4rem',
            },
            '@media (min-width: 1440px)': {
              fontSize: '5rem',
            },
          }}
        >
          <style>
            {`
          @keyframes rotate {
            0% { transform: rotate(0deg); }
            50% { transform: rotate(20deg); }
            100% { transform: rotate(0deg); }
          }
        `}
          </style>
          <img
            src="/Logo_PNG/FullColor.png"
            alt="PennPRS"
            style={{
              width: "55px",
              height: "55px",
              animation: "rotate 2s ease-in-out",
              marginRight: "1rem",
            }}
          />
          <span style={{
            background: "linear-gradient(90deg, #003366, #005b99, #0099cc)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent"
          }}>
            PennPRS
          </span>

        </Typography>

        {/* Content Section */}
        <Box
          sx={{
            width: "80%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            // backgroundColor: "#ffffff",
            // borderRadius: "15px",
            padding: "1rem",
            // color: "#0d47a1", 
          }}
        >
          {/* Text Section */}


          <Typography
            variant="h6"
            component="h6"
            sx={{ lineHeight: "1.2", textAlign: "left" }}
          >
            PennPRS is a centralized cloud computing platform for efficient polygenic risk score (PRS) model training in precision medicine.
            Users can either upload their own GWAS summary data or directly query data from public <a href="/data" target="_blank" style={{ color: "#0d47a1" }}> data sources</a> we provide.
            PennPRS supports both <a href="https://pennprs.gitbook.io/pennprs/user-manual/single-ancestry-analysis" target="_blank" style={{ color: "#0d47a1" }}>single-ancestry</a> and <a href="https://pennprs.gitbook.io/pennprs/user-manual/multi-ancestry-analysis" target="_blank" style={{ color: "#0d47a1" }}>multi-ancestry</a> PRS training.
          </Typography>



          {/*Features Section */}
          {/* <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "space-between",
              mt: 3,
            }}
          >
             Currently support online training for seven single-/multi-ancestry methods
             An offline version supporting 10 methods
             XXX queryable GWAS from the GWAS Catalog
             Publish trained PRS models for XXX traits/diseases based on GWAS data across five superpopulations and 7 studies
            </Box> */}

          <Box display="flex" flexDirection="column" gap={2} p={3}>
            {items.map((item, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{
                  delay: index * 0.3, // Stagger the animation by index
                  duration: 0.5,
                  ease: "easeInOut"
                }}
              >
                <Typography variant="h6" sx={{ fontSize: 18, color: 'text.primary' }}>
                  {item}
                </Typography>
              </motion.div>
            ))}
          </Box>


          {/* Updates and Citation Section */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1, ease: "easeInOut" }}
            width="100%"
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between",
                mt: 3,
                gap: 6,
              }}
            >
              {/* Updates Section */}
              <Box
                sx={{
                  width: "48%",
                  backgroundColor: "#e3f2fd",
                  borderRadius: "10px",
                  padding: "1.5rem",
                  marginRight: "2%",
                  transition: "transform 0.3s ease",
                  ":hover": {
                    transform: "translateY(-5px)",
                  },
                }}
              >
                <Typography
                  variant="h6"
                  sx={{ fontWeight: "bold", color: "#0d47a1" }}
                >
                  Updates
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ color: "#0d47a1" }}
                >
                  For more information, please visit our <a href="/updates" target="_blank" style={{ color: "#0d47a1" }}><b>updates page</b></a>.
                </Typography>
                <br />
                <Box
                  sx={{
                    maxHeight: "80px",
                    overflowY: "auto",
                    scrollbarWidth: "thin",
                    "&::-webkit-scrollbar": {
                      width: "5px",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      backgroundColor: "#0d47a1",
                      borderRadius: "5px",
                    },
                    border: "1px solid #0d47a1",
                    borderRadius: "5px"
                  }}
                >
                  <List dense sx={{ padding: 0, margin: 0 }}>



                    <ListItem>
                      <ListItemText
                        primary={<Typography><b>2025-03-12: </b>
                          PennPRS version 1.0.1 is now available. This update includes supporting direct query from over 2,400 binary disease phenotypes from the FinnGen project.
                        </Typography>}
                      />
                    </ListItem>

                    <ListItem>
                      <ListItemText
                        primary={<Typography><b>2025-02-27: </b>
                          The user interface has been updated based on feedback. Additionally, the log file and results have been restructured for improved clarity and organization.
                        </Typography>}
                      />
                    </ListItem>

                    <ListItem>
                      <ListItemText
                        primary={<Typography><b>2025-01-25: </b>
                          &nbsp;PennPRS version 1.0.0 was released.
                        </Typography>}
                      />
                    </ListItem>

                    <ListItem>
                      <ListItemText
                        primary={<Typography><b>2024-11-19: </b>
                          &nbsp;The <a href="https://pennprs.gitbook.io/pennprs" target="_blank" style={{ color: "#0d47a1" }}> tutorial</a> is available for users.
                        </Typography>}
                      />
                    </ListItem>

                    <ListItem>
                      <ListItemText
                        primary={<Typography><b>2024-10-13: </b> PennPRS is now live!</Typography>}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary={<Typography><b>2024-09-25: </b> Internal testing phase completed.</Typography>}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary={<Typography><b>2024-05-15: </b> Demo version of PennPRS is available.</Typography>}
                      />
                    </ListItem>
                  </List>
                </Box>
              </Box>

              {/* Citation Section */}
              <Box
                sx={{
                  width: "48%",
                  backgroundColor: "#e3f2fd",
                  borderRadius: "10px",
                  padding: "1.5rem",
                  transition: "transform 0.3s ease",
                  ":hover": {
                    transform: "translateY(-5px)",
                  },
                }}
              >
                <Typography
                  variant="h6"
                  sx={{ fontWeight: "bold", color: "#0d47a1" }}
                >
                  Citation
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ color: "#0d47a1" }}
                >
                  If you find PennPRS useful, please cite us in your work:
                  <br />
                </Typography>
                <Box
                  sx={{
                    height: "60px",
                    backgroundColor: "#ffffff",
                    mt: 2,
                    border: "1px solid #0d47a1",
                    borderRadius: "2px",
                    padding: "0.5rem",
                    display: "flex",
                    alignItems: "center",
                  }}
                  onClick={() => {
                    window.open("https://doi.org/10.1101/2025.02.07.25321875", "_blank");
                  }}
                >
                  {/* <code>
                    <Typography variant="body1">
                      <a href="https://doi.org/10.1101/2025.02.07.25321875" target="_blank" style={{ color: "black" }}>PennPRS: a centralized cloud computing platform for efficient polygenic risk score training in precision medicine. medRxiv.</a>
                    </Typography>
                  </code> */}
                  <Tooltip title="Navigate to Paper" arrow>
                    <Typography variant="body2" sx={{ fontSize: 12 }}>
                      Jin, J., Li, B., Wang, X., Yang, X., Li, Y., Wang, R., Ye, C., Shu, J., Fan, Z., Xue, F., Ge, T., Ritchie, M.D., Pasaniuc, B., Wojcik, G., Zhao, B. PennPRS: a Centralized Cloud Computing Platform for Efficient Polygenic Risk Score Training in Precision Medicine. medRxiv, 2025. DOI: 10.1101/2025.02.07.25321875.
                    </Typography>
                  </Tooltip>
                </Box>
              </Box>
            </Box>
          </motion.div>
        </Box>

        {/* Scroll Down Button */}
        {!more && (
          <Box
            sx={{
              position: "fixed",
              bottom: 18,
              textAlign: "center",
              width: "100%",
            }}
          >
            <IconButton
              onClick={scrollToContent}
              sx={{
                animation: "jump 0.8s infinite",
                "@keyframes jump": {
                  "0%, 100%": {
                    transform: "translateY(0)",
                  },
                  "50%": {
                    transform: "translateY(-10px)",
                  },
                },
                ":hover": {
                  animation: "none",
                },
              }}
            >
              <ArrowDownwardIcon fontSize="large" />
            </IconButton>
          </Box>
        )}
        {more && (
          <Typography variant="h5" fontWeight="bold" mt={7} mb={5} color={"#0d47a1"}>
            Select one of the following options to start.
          </Typography>
        )}
      </Box>

      {/* Lower Section with the Same Background Gradient */}
      <Box
        sx={{
          width: "100vw",
          minHeight: "30vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          // background: " #e0e0e0",
          background: "#F8F8F8",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-evenly",
            backgroundColor: "transparent",
          }}
        >
          <MethodCard
            name="📊 Single-Ancestry Analysis"
            introduction="PRS model training using GWAS data from a single ancestry."
            onClick={handleRedirectSA}
          />
          <MethodCard
            name=" 📊 Multi-Ancestry Analysis"
            introduction="PRS model training using GWAS data from multiple ancestries."
            onClick={handleRedirectMA}
          />
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-evenly",
            backgroundColor: "transparent",
            mt: 3,
          }}
        >
          <MethodCard
            name="📃 Tutorial"
            introduction="View the tutorial to learn how to use the platform."
            onClick={handleRedirectTutorial}
          />
          {/* <MethodCard
            name="📪 Forum"
            introduction="Post questions or view asked questions."
            onClick={handleRedirectFQA}
          /> */}
          <MethodCard
            name="📤 FAQs"
            introduction="View frequently asked questions."
            onClick={() => navigate("/faq")}
          />
        </Box>

        <Box
          sx={{
            width: "90%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            mt: 12,
            borderTop: "0.5px solid #0d47a1",
          }}
        >


          <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
            <Typography variant="h6" fontWeight="bold" sx={{ color: "#0d47a1", mb: 2, mt: 1 }}>
              Developed by
            </Typography>

            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "space-between", gap: 1 }}>

              <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "flex-start" }}>
                <Typography variant="body1">
                  Bingxin Zhao
                </Typography>
                <Typography variant="body1">
                  Bingxuan Li
                </Typography>
              </Box>

              <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "flex-end" }}>
                <Typography variant="body1">
                  Jin Jin
                </Typography>
                <Typography variant="body1">
                  Xiyao Wang
                </Typography>
              </Box>

            </Box>

            <Typography variant="body1" sx={{ mt: 1 }}>
              <i>Contact:</i> <a href="mailto:pennprs@googlegroups.com" style={{ color: "#0d47a1" }}>PennPRS Team</a>
            </Typography>
          </Box>


          {/* Logos */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              gap: 2,
              mt: 1,
            }}
          >
            <img src="School_Logo/wharton_logo.png" alt="Wharton" style={{ height: "120px" }} />
            <img src="School_Logo/pennmedi.png" alt="PennMEDI" style={{ height: "54px" }} />
            <img src="School_Logo/purdue.png" alt="Purdue" style={{ height: "38px" }} />
          </Box>


          <a href="https://clustrmaps.com/site/1c23d" title="Visit tracker" target="_blank"><img src="//www.clustrmaps.com/map_v2.png?d=_vpuCFOT_FmRZw2LLUREysPz4Qm8BapmVJMR6XPt5dE&cl=ffffff" /></a>


        </Box>

        <Box
          sx={{
            width: "80%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mt: 1,
          }}
        >
          <Typography variant="body1" sx={{ mr: 2, color: "grey" }}>
            © 2024-2025 PennPRS. All rights reserved.
          </Typography>

        </Box>
      </Box>
    </div >
  );
};
