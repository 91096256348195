import React, { useEffect, useState } from 'react';
import {
  Container, Typography, Table, TableBody, TableCell, TableContainer,
  TableHead, TableRow, Paper, Button, CircularProgress
} from '@mui/material';
import { useSelector } from "react-redux";
import apis from '../../api';

const AUTHORIZED_EMAILS = [
  'bxzhao@upenn.edu', 'hjazura@gmail.com', 'xiyao2000@gmail.com', 
  'bx3393@gmail.com', 'libingxuan1206@gmail.com'
];

export const AdminPage = () => {
  const userEmail = useSelector(state => state.user.email);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [jobInfo, setJobInfo] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!AUTHORIZED_EMAILS.includes(userEmail)) {
      alert('You are not authorized to view this page.');
      window.location.href = '/';
      return;
    }
    setIsAuthorized(true);
    fetchData();
  }, [userEmail]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const res = await apis.getAdminDashboard();
      setJobInfo(res.job_info);
      setUserInfo(res.user_info);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  if (!isAuthorized) return null;
  if (loading) return <Container><CircularProgress /></Container>;

  return (
    <Container maxWidth="lg" sx={{ py: 5 }}>
      <Typography variant="h4" align="center" gutterBottom sx={{ mt: 5 }}>
        <b>PennPRS Admin Dashboard</b>
        <Button variant="outlined" onClick={fetchData} sx={{ m: 2 }}>
        Refresh Data
      </Button>
      </Typography>

      <Typography variant="body1"  align="center" gutterBottom sx={{ m: 3 }}>
        <b>Number of users:</b> {userInfo?.['user_details'] ? Object.keys(userInfo['user_details']).length : 0}   |  <b>Number of completed jobs:</b> {userInfo?.['user_details'] ? Object.values(userInfo['user_details']).reduce((acc, curr) => acc + curr['number_of_completed_jobs'], 0) : 0}
      </Typography>
      

      {/* Job Information */}
      <Typography variant="h5" gutterBottom>
        Job Information
      </Typography>
      <TableContainer component={Paper} elevation={0}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell><b>Job Type</b></TableCell>
              <TableCell><b>Running</b></TableCell>
              <TableCell><b>Queued</b></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {['Single', 'Multi', 'Auto'].map(type => (
              <TableRow key={type}>
                <TableCell>{type}</TableCell>
                <TableCell>{jobInfo?.[`number of running jobs of ${type.toLowerCase()} type`] ?? 0}</TableCell>
                <TableCell>{jobInfo?.[`number of queued jobs of ${type.toLowerCase()} type`] ?? 0}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Running Jobs */}
      <Typography variant="h6" gutterBottom sx={{ mt: 5 }}>
        Running Jobs
      </Typography>
      {jobInfo?.['current running jobs'] ? (
        <TableContainer component={Paper} elevation={0}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell><b>Job ID</b></TableCell>
                <TableCell><b>User Email</b></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(jobInfo['current running jobs']).map(([jobId, email]) => (
                <TableRow key={jobId}>
                  <TableCell>{jobId}</TableCell>
                  <TableCell>{email}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography>No running jobs available.</Typography>
      )}

      {/* User Information */}
      <Typography variant="h5" gutterBottom sx={{ mt: 5}}>
        User Information
      </Typography>
      {userInfo?.['user_details'] ? (
        <TableContainer component={Paper} elevation={0}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell><b>Email</b></TableCell>
                <TableCell><b>First Name</b></TableCell>
                <TableCell><b>Last Name</b></TableCell>
                <TableCell><b>Organization</b></TableCell>
                <TableCell><b>Total Jobs</b></TableCell>
                <TableCell><b>Completed</b></TableCell>
                <TableCell><b>Failed</b></TableCell>
                <TableCell><b>Running</b></TableCell>
                <TableCell><b>Queued</b></TableCell>
                <TableCell><b>Killed</b></TableCell>
                <TableCell><b>SignUp Date</b></TableCell>
                <TableCell><b>Last Submitted</b></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(userInfo['user_details']).map(([email, details]) => (
                <TableRow key={email}>
                  <TableCell>{email}</TableCell>
                  <TableCell>{details?.['first name'] ?? 'N/A'}</TableCell>
                  <TableCell>{details?.['last name'] ?? 'N/A'}</TableCell>
                  <TableCell>{details?.['orgnization'] ?? 'N/A'}</TableCell>
                  <TableCell>{details?.['total_jobs'] ?? 0}</TableCell>
                  <TableCell>{details?.['number_of_completed_jobs'] ?? 0}</TableCell>
                  <TableCell>{details?.['number_of_failed_jobs'] ?? 0}</TableCell>
                  <TableCell>{details?.['number_of_running_jobs'] ?? 0}</TableCell>
                  <TableCell>{details?.['number_of_queued_jobs'] ?? 0}</TableCell>
                  <TableCell>{details?.['number_of_killed_jobs'] ?? 0}</TableCell>
                  <TableCell>{details?.['signup_date'] ?? 'N/A'}</TableCell>
                  <TableCell>{details?.['latest_job_submited_time'] ?? 'N/A'}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography>No user data available.</Typography>
      )}
      <Typography variant="h6" gutterBottom sx={{ mt: 5, mb: 5}}>
      </Typography>

    </Container>
  );
};
