import React, { useState, useEffect } from "react";

const fieldMappings = {
  "GWAS Catalog": {
    filterFields: ["pubmedId", "studyAccession", "diseaseTrait"],
    headers: [
      { label: "Study Accession", key: "studyAccession" },
      { label: "Date", key: "date" },
      { label: "PUBMED", key: "pubmedId" },
      { label: "Disease/Trait", key: "diseaseTrait" },
      { label: "Sample Size & Population", key: "initialSampleSize" },
      { label: "Link", key: "url", isLink: true },
    ],
  },
  "FinnGen": {
    filterFields: ["phenocode", "phenotype"],
    headers: [
      { label: "Phenocode", key: "phenocode" },
      { label: "Phenotype", key: "phenotype" },
      { label: "Category", key: "category" },
      { label: "Cases", key: "num_cases" },
      { label: "Controls", key: "num_controls" },
      { label: "Link", key: "url", isLink: true },
    ],
  },
};

// Map data source names to TSV endpoint URLs.
const dataSources = {
  "GWAS Catalog": "/gwas_catalog.tsv",
  "FinnGen": "/finngen.tsv",
};

// Helper function to parse TSV text into an array of objects.
const parseTSV = (text, type) => {
  // Split text into non-empty lines.
  const lines = text.split("\n").filter((line) => line.trim() !== "");
  return lines.map((line) => {
    const fields = line.split("\t");
    if (type === "GWAS Catalog") {
      if (fields.length === 6) {
        const [pubmedId, diseaseTrait, date, initialSampleSize, studyAccession, url] =
          fields;
        return { pubmedId, diseaseTrait, date, initialSampleSize, studyAccession, url };
      }
    } else if (type === "FinnGen") {
      if (fields.length === 6) {
        const [phenocode, phenotype, category, num_cases, num_controls, url] = fields;
        return { phenocode, phenotype, category, num_cases, num_controls, url };
      }
    }
    console.warn(`Skipping line due to incorrect format: ${line}`);
    return null;
  }).filter((row) => row !== null);
};

export const TutorialPage = () => {
  const [activeTab, setActiveTab] = useState("GWAS Catalog");
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const rowsPerPage = 10;
  const maxPageNumbersToShow = 5;

  // Load data when the activeTab changes.
  useEffect(() => {
    setLoading(true);
    setError(null);
    fetch(dataSources[activeTab])
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.text();
      })
      .then((text) => {
        const data = parseTSV(text, activeTab);
        setTableData(data);
        setLoading(false);
        setCurrentPage(1); // Reset to first page on data load
      })
      .catch((error) => {
        console.error("Error loading table data:", error);
        setError(error.message);
        setLoading(false);
      });
  }, [activeTab]);

  // Filter data based on the active tab’s filterFields.
  const filterData = (data, fields, term) => {
    if (!term) return data;
    const lowerTerm = term.toLowerCase();
    return data.filter((row) =>
      fields.some((field) => {
        const value = row[field];
        return value && value.toString().toLowerCase().includes(lowerTerm);
      })
    );
  };

  const currentMapping =
    fieldMappings[activeTab] || fieldMappings["GWAS Catalog"];
  const filteredData = filterData(
    tableData,
    currentMapping.filterFields,
    searchTerm
  );

  // Calculate pagination details.
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = filteredData.slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(filteredData.length / rowsPerPage);

  const handleClickPage = (pageNumber) => setCurrentPage(pageNumber);
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1);
  };

  const handlePrevious = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleNext = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const startPage = Math.max(1, currentPage - Math.floor(maxPageNumbersToShow / 2));
  const endPage = Math.min(totalPages, startPage + maxPageNumbersToShow - 1);
  const pageNumbers = Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);

  return (
    <div
      style={{
        padding: "20px",
        marginTop: "56px",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <h1>Queryable Database</h1>

      {/* Tab Navigation */}
      <div
        style={{
          display: "flex",
          marginBottom: "10px",
          borderBottom: "2px solid #ddd",
          justifyContent: "center",
        }}
      >
        {Object.keys(dataSources).map((tab) => (
          <button
            key={tab}
            onClick={() => {
              setActiveTab(tab);
              setSearchTerm("");
              setCurrentPage(1);
            }}
            style={{
              padding: "10px 20px",
              cursor: "pointer",
              border: "none",
              borderBottom: activeTab === tab ? "3px solid #1b65b4" : "none",
              backgroundColor: "transparent",
              fontWeight: activeTab === tab ? "bold" : "normal",
              fontSize: "16px",
              color: activeTab === tab ? "#1b65b4" : "#333",
              marginRight: "10px",
            }}
          >
            {tab}
          </button>
        ))}
      </div>

      
      {/* {activeTab === "FinnGen" && (
        <p style={{ textAlign: "center", fontSize: "14px", color: "#555" }}>
          FinnGen data details can be found on the official website.
        </p>
      )} */}

      {/* Search Bar */}
      <input
        type="text"
        placeholder={`Search in ${activeTab}`}
        value={searchTerm}
        onChange={handleSearchChange}
        style={{
          width: "80%",
          padding: "12px 20px",
          margin: "8px 0",
          boxSizing: "border-box",
          fontSize: "16px",
          border: "1px solid #ccc",
          borderRadius: "4px",
        }}
      />

      {/* Table */}
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p>Error: {error}</p>
      ) : (
        <table
          style={{
            width: "100%",
            borderCollapse: "collapse",
            margin: "20px 0",
            fontSize: "14px",
            textAlign: "left",
            fontFamily: '"Arial", sans-serif',
            color: "#333",
            border: "1px solid #999",
          }}
        >
          <thead>
            <tr>
              {currentMapping.headers.map((header) => (
                <th
                  key={header.key}
                  style={{
                    padding: "10px",
                    border: "1px solid #999",
                    backgroundColor: "#f1f1f1",
                    fontWeight: "bold",
                    letterSpacing: "0.5px",
                    textTransform: "uppercase",
                    textAlign: "center",
                  }}
                >
                  {header.label}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {currentRows.length > 0 ? (
              currentRows.map((row, index) => (
                <tr
                  key={index}
                  style={{
                    backgroundColor: index % 2 === 0 ? "#f9f9f9" : "white",
                  }}
                >
                  {currentMapping.headers.map((header) => (
                    <td
                      key={header.key}
                      style={{
                        padding: "10px",
                        border: "1px solid #999",
                        textAlign: "center",
                      }}
                    >
                      {header.isLink ? (
                        row[header.key] === "NA" ? (
                          "NA"
                        ) : (
                          <a
                            href={row[header.key]}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              color: "#0056b3",
                              textDecoration: "underline",
                            }}
                          >
                            Link
                          </a>
                        )
                      ) : (
                        row[header.key]
                      )}
                    </td>
                  ))}
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan={currentMapping.headers.length}
                  style={{ textAlign: "center", padding: "20px" }}
                >
                  No results found.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      )}

      {/* Pagination */}
      {!loading && !error && totalPages > 1 && (
        <>
          <div style={{ textAlign: "center", marginTop: "20px" }}>
            <button
              onClick={handlePrevious}
              disabled={currentPage === 1}
              style={{
                margin: "0 5px",
                padding: "5px 10px",
                backgroundColor: currentPage === 1 ? "#ccc" : "#007bff",
                color: "white",
                border: "none",
                borderRadius: "3px",
                cursor: currentPage === 1 ? "not-allowed" : "pointer",
              }}
            >
              Previous
            </button>
            {pageNumbers.map((pageNumber) => (
              <button
                key={pageNumber}
                onClick={() => handleClickPage(pageNumber)}
                style={{
                  margin: "0 5px",
                  padding: "5px 10px",
                  backgroundColor:
                    currentPage === pageNumber ? "#007bff" : "#f1f1f1",
                  color: currentPage === pageNumber ? "white" : "black",
                  border: "none",
                  borderRadius: "3px",
                  cursor: "pointer",
                }}
              >
                {pageNumber}
              </button>
            ))}
            <button
              onClick={handleNext}
              disabled={currentPage === totalPages}
              style={{
                margin: "0 5px",
                padding: "5px 10px",
                backgroundColor:
                  currentPage === totalPages ? "#ccc" : "#007bff",
                color: "white",
                border: "none",
                borderRadius: "3px",
                cursor:
                  currentPage === totalPages ? "not-allowed" : "pointer",
              }}
            >
              Next
            </button>
          </div>
          <p
            style={{
              textAlign: "center",
              marginTop: "10px",
              fontSize: "14px",
              color: "#555",
            }}
          >
            Page {currentPage} of {totalPages}
          </p>
        </>
      )}
    </div>
  );
};
