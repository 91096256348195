import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Slider,
  Box,
  Button,
  TextField,
  Typography,
  Grid,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  Input,
  Select,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import apis from "../../../api";
import { useSelector, useDispatch } from "react-redux";
import {
  setTraitsSource,
  setTraitsDetail,
  setTraitsType,
  setTraitsName,
  setTraitsPopulation,
  setTraitsCol,
} from "../../../store/reducers/job";
import StatusBox from "../../../component/StatusBox";
import AlertWindow from "../../../component/AlertWindow";

export const DataBlock = ({ setStep }) => {
  const dispatch = useDispatch();
  const userEmail = useSelector((state) => state.user.email);
  const store_types = useSelector((state) => state.job.traitsType);
  const store_sources = useSelector((state) => state.job.traitsSource);
  const store_details = useSelector((state) => state.job.traitsDetail);
  const store_names = useSelector((state) => state.job.traitsName);
  const store_population = useSelector((state) => state.job.traitsPopulation);
  const store_col = useSelector((state) => state.job.traitsCol);

  // State for number of ancestries
  const [ancestryCount, setAncestryCount] = useState(store_names.length < 2 ? 2 : store_names.length);
  const marks = [
    { value: 2, label: "2" },
    { value: 3, label: "3" },
    { value: 4, label: "4" },
    { value: 5, label: "5" },
  ];

  const allPopulations = [
    "European (EUR)",
    "African, Admixed African, African American (AFR)",
    "Admixed American,  Hispanic / Latino (AMR)",
    "East Asian (EAS)",
    "South Asian (SAS)",
  ];

  // State for each ancestry data
  const [ancestryData, setAncestryData] = useState([]);

  // Alert state
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertTitle, setAlertTitle] = useState("");

  // Handle alert close
  const handleAlertClose = () => {
    setAlertOpen(false);
    setAlertMessage("");
    setAlertTitle("");
  };

  // Initialize ancestry data when ancestryCount changes
  useEffect(() => {
    const initialAncestryData = Array.from(
      { length: ancestryCount },
      (_, idx) => ({
        name: store_names[idx] || "",
        type: store_types[idx] || "",
        source: store_sources[idx] || "",
        detail: store_details[idx] || "",
        population: store_population[idx] || "",
        colNames: store_col[idx] || {
          chr: "",
          snp: "",
          a1: "",
          a2: "",
          beta: "",
          se: "",
          p: "",
          maf: "",
          n: "",
          ncase: "",
          ncontrol: "",
          neff: "",
          id: "",
        },
        nType: "",
        uploadedFileName: "",
        uploading: false,
        columnsMatched: null,
        sourceStatus: "required",
        colNamesStatus: "required",
        sampleSizeStatus: "required",
        pastDataNames: ["No data uploaded"],
        pastData: [],
        selectedPastDataName: "No data uploaded",
      })
    );
    setAncestryData(initialAncestryData);
    initialAncestryData.forEach((data, index) => {
      const sourceStatus = updateSourceStatus(data);
      const sampleSizeStatus = updateSampleSizeStatus(data);
      const colNamesStatus = updateColNamesStatus(data);
      initialAncestryData[index].sourceStatus = sourceStatus;
      initialAncestryData[index].sampleSizeStatus = sampleSizeStatus;
      initialAncestryData[index].colNamesStatus = colNamesStatus;
    });

  }, [ancestryCount]);

  const getAvailablePopulations = (currentIndex) => {
    const selectedPopulations = ancestryData
      .filter((_, idx) => idx !== currentIndex)
      .map((data) => data.population)
      .filter((pop) => pop !== "");

    return allPopulations.filter((pop) => !selectedPopulations.includes(pop));
  };

  // Functions to update statuses
  const updateSourceStatus = (data) => {
    const { source, uploadedFileName, colNames, detail } = data;
    let sourceStatus = "required";

    if (source === "Upload Data") {
      sourceStatus = uploadedFileName === "" ? "required" : "ok";
    } else if (source === "Query Data") {
      if (detail === "GWAS Catalog") {
        if (colNames.id === "" || !colNames.id.startsWith("GCST")) {
          sourceStatus = "required";
        } else {
          sourceStatus = "ok";
        }
      } else if (detail === "OpenGWAS") {
        sourceStatus = "ok";
      }
    } else if (source === "Previous Data") {
      sourceStatus = "ok";
    } else {
      sourceStatus = "required";
    }
    return sourceStatus;
  };

  const updateSampleSizeStatus = (data) => {
    const { source, detail, type, colNames, nType } = data;
    let sampleSizeStatus = "required";

    if (source === "Query Data") {
      if (detail === "GWAS Catalog") {
        if (type === "Binary") {
          if (
            colNames.ncase === "" &&
            colNames.ncontrol === "" &&
            colNames.neff === ""
          ) {
            sampleSizeStatus = "required";
          } else if (
            (colNames.ncase !== "" && isNaN(colNames.ncase)) ||
            (colNames.ncontrol !== "" && isNaN(colNames.ncontrol)) ||
            (colNames.neff !== "" && isNaN(colNames.neff))
          ) {
            sampleSizeStatus = "required";
          } else {
            sampleSizeStatus = "ok";
          }
        } else if (type === "Continuous") {
          if (colNames.n === "") {
            sampleSizeStatus = "required";
          } else if (isNaN(colNames.n)) {
            sampleSizeStatus = "required";
          } else {
            sampleSizeStatus = "ok";
          }
        }
      }
    } else {
      if (nType === "n") {
        if (colNames.n === "" || isNaN(colNames.n)) {
          sampleSizeStatus = "required";
        } else {
          sampleSizeStatus = "ok";
        }
      } else if (nType === "ncase") {
        if (
          colNames.ncase === "" ||
          isNaN(colNames.ncase) ||
          colNames.ncontrol === "" ||
          isNaN(colNames.ncontrol)
        ) {
          sampleSizeStatus = "required";
        } else {
          sampleSizeStatus = "ok";
        }
      } else if (nType === "neff") {
        if (colNames.neff === "" || isNaN(colNames.neff)) {
          sampleSizeStatus = "required";
        } else {
          sampleSizeStatus = "ok";
        }
      } else {
        sampleSizeStatus = "required";
      }
    }
    return sampleSizeStatus;
  };

  const updateColNamesStatus = (data) => {
    const { columnsMatched, colNames } = data;
    let colNamesStatus = "required";
    if (columnsMatched === "yes") {
      colNamesStatus = "ok";
    } else if (columnsMatched === "no") {
      const isAnyColNameFilled = Object.values(colNames).some(
        (value) => value !== ""
      );
      colNamesStatus = isAnyColNameFilled ? "ok" : "required";
    } else {
      colNamesStatus = "required";
    }
    return colNamesStatus;
  };

  // Handle slider change
  const handleSliderChange = (event, newValue) => {
    setAncestryCount(newValue);
  };

  // Handle input change for ancestry data
  const handleInputChange = (index, field, value) => {
    const updatedAncestryData = [...ancestryData];
    updatedAncestryData[index][field] = value;

    // When source is changed to "Query Data", set detail to the first available option
    if (field === "source" && value === "Query Data") {
      updatedAncestryData[index].detail = "GWAS Catalog";
    } else if (field === "source") {
      updatedAncestryData[index].detail = "";
    }

    // Update nType based on type
    if (field === "type") {
      if (value === "Binary") {
        updatedAncestryData[index].nType = "ncase";
      } else if (value === "Continuous") {
        updatedAncestryData[index].nType = "n";
      } else {
        updatedAncestryData[index].nType = "";
      }
    }

    // Update statuses
    updatedAncestryData[index].sourceStatus = updateSourceStatus(
      updatedAncestryData[index]
    );
    updatedAncestryData[index].sampleSizeStatus = updateSampleSizeStatus(
      updatedAncestryData[index]
    );
    updatedAncestryData[index].colNamesStatus = updateColNamesStatus(
      updatedAncestryData[index]
    );

    setAncestryData(updatedAncestryData);
  };

  // Handle file upload
  const handleFileChange = async (index, event) => {
    const file = event.target.files[0];
    if (file) {
      const updatedAncestryData = [...ancestryData];
      updatedAncestryData[index].uploading = true;
      setAncestryData(updatedAncestryData);
      try {
        await apis.uploadData(file, userEmail);
        updatedAncestryData[index].uploadedFileName = file.name;
        updatedAncestryData[index].detail = file.name;
      } catch (error) {
        setAlertOpen(true);
        setAlertTitle("❌ Error");
        setAlertMessage(
          "Error while uploading the file. Please try again. Error: " + error
        );
      }
      updatedAncestryData[index].uploading = false;

      // Update sourceStatus
      updatedAncestryData[index].sourceStatus = updateSourceStatus(
        updatedAncestryData[index]
      );

      setAncestryData(updatedAncestryData);
    }
  };

  // Handle column name change
  const handleColNameChange = (index, name, value) => {
    const updatedAncestryData = [...ancestryData];
    updatedAncestryData[index].colNames = {
      ...updatedAncestryData[index].colNames,
      [name]: value,
    };

    // Update statuses
    updatedAncestryData[index].sourceStatus = updateSourceStatus(
      updatedAncestryData[index]
    );
    updatedAncestryData[index].sampleSizeStatus = updateSampleSizeStatus(
      updatedAncestryData[index]
    );
    updatedAncestryData[index].colNamesStatus = updateColNamesStatus(
      updatedAncestryData[index]
    );

    setAncestryData(updatedAncestryData);
  };

  // Handle columnsMatched change
  const handleColumnsMatchedChange = (index, value) => {
    const updatedAncestryData = [...ancestryData];
    updatedAncestryData[index].columnsMatched = value;

    // Update colNamesStatus
    updatedAncestryData[index].colNamesStatus = updateColNamesStatus(
      updatedAncestryData[index]
    );

    setAncestryData(updatedAncestryData);
  };

  // Handle submit
  const handleSubmit = (e) => {
    e.preventDefault();

    // Check for duplicate populations
    const selectedPopulations = ancestryData.map((data) => data.population);
    const uniquePopulations = new Set(selectedPopulations);

    if (uniquePopulations.size !== selectedPopulations.length) {
      setAlertOpen(true);
      setAlertTitle("⚠️ Warning");
      setAlertMessage(
        "Please ensure that each ancestry is assigned to a unique ancestry population."
      );
      return;
    }

    for (let i = 0; i < ancestryCount; i++) {
      const {
        source,
        name,
        uploading,
        uploadedFileName,
        sourceStatus,
        colNamesStatus,
        sampleSizeStatus,
        population,
        columnsMatched,
        type,
        colNames,
      } = ancestryData[i];

      if (source === "") {
        setAlertOpen(true);
        setAlertTitle("⚠️ Warning");
        setAlertMessage(
          `Please select the source of your GWAS summary statistics for Ancestry #${i + 1
          }.`
        );
        return;
      }

      if (name === "") {
        setAlertOpen(true);
        setAlertTitle("⚠️ Warning");
        setAlertMessage(
          `Please enter the name of the trait for Ancestry #${i + 1}.`
        );
        return;
      }

      if (uploading) {
        setAlertOpen(true);
        setAlertTitle("⚠️ Warning");
        setAlertMessage(
          `Please wait for the file upload to complete for Ancestry #${i + 1}.`
        );
        return;
      }

      if (source === "Upload Data" && uploadedFileName === "") {
        setAlertOpen(true);
        setAlertTitle("⚠️ Warning");
        setAlertMessage(
          `Please upload the GWAS file or select another GWAS source for Ancestry #${i + 1
          }.`
        );
        return;
      }

      if (sourceStatus !== "ok") {
        setAlertOpen(true);
        setAlertTitle("⚠️ Warning");
        setAlertMessage(
          `Please complete the source information for Ancestry #${i + 1}.`
        );
        return;
      }

      if (population === "") {
        setAlertOpen(true);
        setAlertTitle("⚠️ Warning");
        setAlertMessage(
          `Please select the population of your input data for Ancestry #${i + 1
          }.`
        );
        return;
      }

      if (type === "") {
        setAlertOpen(true);
        setAlertTitle("⚠️ Warning");
        setAlertMessage(
          `Please select the trait type for Ancestry #${i + 1}.`
        );
        return;
      }

      if (source === "Query Data" && sampleSizeStatus !== "ok") {
        setAlertOpen(true);
        setAlertTitle("⚠️ Warning");
        setAlertMessage(
          `Please enter valid sample size information for Ancestry #${i + 1}.`
        );
        return;
      }

      if (source !== "Query Data") {
        if (columnsMatched === null) {
          setAlertOpen(true);
          setAlertTitle("⚠️ Warning");
          setAlertMessage(
            `Please confirm if your columns are matched to the required format for Ancestry #${i + 1
            }.`
          );
          return;
        }

        if (columnsMatched === "no" && colNamesStatus !== "ok") {
          setAlertOpen(true);
          setAlertTitle("⚠️ Warning");
          setAlertMessage(
            `Please fill in at least one column name for Ancestry #${i + 1}.`
          );
          return;
        }
      }

      if (source === "Query Data") {
        if (colNames.id === "") {
          setAlertOpen(true);
          setAlertTitle("⚠️ Warning");
          setAlertMessage(
            `Please enter the Trait ID for Ancestry #${i + 1}.`
          );
          return;
        }

        if (!colNames.id.startsWith("GCST")) {
          setAlertOpen(true);
          setAlertTitle("⚠️ Warning");
          setAlertMessage(
            `Please enter a valid Trait ID starting with 'GCST' for Ancestry #${i + 1
            }.`
          );
          return;
        }
      }
    }

    const sources = ancestryData.map((data) => data.source);
    const details = ancestryData.map((data) => data.detail);
    const types = ancestryData.map((data) => data.type);
    const names = ancestryData.map((data) => data.name);
    const populations = ancestryData.map((data) => data.population);
    const cols = ancestryData.map((data) => data.colNames);

    dispatch(setTraitsSource(sources));
    dispatch(setTraitsDetail(details));
    dispatch(setTraitsType(types));
    dispatch(setTraitsName(names));
    dispatch(setTraitsPopulation(populations));
    dispatch(setTraitsCol(cols));

    setStep(2);
  };

  const gridItemStyle = {
    padding: 2,
    border: "1px solid #ddd",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    p: 2,
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        mt: 2,
        boxSizing: "border-box",
      }}
    >
      <AlertWindow
        open={alertOpen}
        title={alertTitle}
        message={alertMessage}
        onClose={handleAlertClose}
      />

      {/* Slider to select number of ancestries */}
      <Box
        sx={{
          width: "90%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          border: "1px solid #ddd",
          borderRadius: "5px",
          pt: 2,
          pl: 2,
          mb: 4,
        }}
      >
        <Typography variant="h5" sx={{ mb: 1 }}>
          How many ancestries would you like to analyze?
        </Typography>
        <Box sx={{ bgcolor: "grey.300", p: 1, borderRadius: 1, mb: 3 }}>
          <Typography variant="body1">
            For multi-ancestry analysis on 2 &le; K &le; 5 distinct ancestries, we require ancestry-stratified GWAS summary statistics from the K ancestry groups. We support analyses on any subset of the five <a href="https://useast.ensembl.org/Help/View?id=171">super populations</a> with ancestry-stratified GWAS summary statistics from the corresponding ancestry populations. For analyses on more complex ancestry populations, please <a href="mailto:pennprs@googlegroups.com" target="_blank"> contact us</a>.
          </Typography>
        </Box>

        {/* Dropdown to select number of ancestries */}
        <Box sx={{ width: 300, mb: 4 }}>
          <FormControl fullWidth>
            <Select
              value={ancestryCount}
              onChange={(event) => setAncestryCount(event.target.value)}
              displayEmpty
            >
              <MenuItem value={2}>2</MenuItem>
              <MenuItem value={3}>3</MenuItem>
              <MenuItem value={4}>4</MenuItem>
              <MenuItem value={5}>5</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>


      {/* Render forms for each ancestry */}
      {ancestryData.map((data, index) => (
        <Accordion
          key={index}
          sx={{ width: "95%", mb: 4, backgroundColor: "#808080", borderRadius: "5px" }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel-content-${index}`}
            id={`panel-header-${index}`}
          >
            <Typography variant="h6" color="white">Ancestry #{index + 1}:</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ backgroundColor: "#f5f5f5" }}>
            {/* Form code for each ancestry */}
            <Box
              sx={{
                width: "100%",
                display: "flex",
                overflow: "auto",
                boxSizing: "border-box",
                justifyContent: "center",
                alignItems: "center",
                pt: 2,
                pl: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  boxSizing: "border-box",
                }}
              >
                <Grid
                  container
                  spacing={{ xs: 2, md: 2 }}
                  columns={{ xs: 12, sm: 6, md: 12 }}
                >
                  {/* Data Sources */}
                  <Grid item xs={4} sx={gridItemStyle}>
                    <Typography variant="h6" sx={{ mb: 1 }}>
                      GWAS Summary Statistics:
                    </Typography>
                    {/* Instructions based on selected source */}
                    {data.source === "Query Data" && (
                      <Box sx={{ bgcolor: "grey.300", p: 1, borderRadius: 1 }}>
                        <Typography variant="body2">
                          Please enter the information of the GWAS summary statistics.
                        </Typography>
                        <br />
                        <Typography variant="body2">
                          Note: You can query harmonized GWAS Catalog datasets provided on the <a href="/data" target="_blank">Queryable Data</a> page. Simply search for the trait of interest, then start by entering the Trait ID (e.g., GCST90320257). Please check <a href="https://pennprs.gitbook.io/pennprs/user-manual/single-ancestry-analysis#option-2.-query-data-from-the-public-gwas-summary-database-provided-by-pennprs" target="_blank">Tutorial</a> for more information.
                        </Typography>
                      </Box>
                    )}
                    {data.source === "Upload Data" && (
                      <Box sx={{ bgcolor: "grey.300", p: 1, borderRadius: 1 }}>
                        <Typography variant="body2">
                          We support the following file formats: <b>.txt, .tsv, .csv, .zip, .gz, .gzip,</b> and <b>.tar.gz</b>. We accept file separated by tab, comma, or space. The file should contain the following columns: <b>CHR, SNP, A1, A2, BETA, SE, P, MAF, N</b>. Maximum file size allowed: <b>800MB</b>.
                        </Typography>
                      </Box>
                    )}
                  </Grid>
                  <Grid item xs={4} sx={gridItemStyle}>
                    <FormControl component="fieldset">
                      <RadioGroup
                        row
                        value={data.source}
                        onChange={(e) =>
                          handleInputChange(index, "source", e.target.value)
                        }
                      >
                        <FormControlLabel
                          value="Query Data"
                          control={<Radio />}
                          label="Query Data"
                        />
                        <FormControlLabel
                          value="Upload Data"
                          control={<Radio />}
                          label="Upload Data"
                        />
                      </RadioGroup>
                      {/* Upload Data */}
                      {data.source === "Upload Data" &&
                        data.uploadedFileName === "" && (
                          <Input
                            type="file"
                            onChange={(e) => handleFileChange(index, e)}
                            sx={{ mt: 2 }}
                            disabled={data.uploading}
                          />
                        )}
                      {data.source === "Upload Data" && data.uploading && (
                        <CircularProgress size={20} />
                      )}
                      {data.source === "Upload Data" &&
                        data.uploadedFileName !== "" && (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: 2,
                              mt: 1,
                            }}
                          >
                            <Typography variant="body">
                              Successfully uploaded: {data.uploadedFileName}
                            </Typography>
                            <Button
                              variant="outlined"
                              onClick={() =>
                                handleInputChange(index, "uploadedFileName", "")
                              }
                              sx={{ mt: 0 }}
                            >
                              Re-upload
                            </Button>
                          </Box>
                        )}
                      {/* Query Data */}
                      {data.source === "Query Data" && (
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 2,
                            mt: 2,
                            width: "100%",
                          }}
                        >
                          <FormControl variant="outlined" fullWidth>
                            <Select
                              value={data.detail}
                              onChange={(e) =>
                                handleInputChange(index, "detail", e.target.value)
                              }
                            >
                              <MenuItem value={"GWAS Catalog"}>
                                GWAS Catalog
                              </MenuItem>
                              {/* Add more options if needed */}
                            </Select>
                          </FormControl>

                          <TextField
                            label="Trait ID"
                            variant="outlined"
                            value={data.colNames.id}
                            sx={{ width: "100%" }}
                            onChange={(e) =>
                              handleColNameChange(index, "id", e.target.value)
                            }
                            error={
                              !data.colNames.id.startsWith("GCST") &&
                              data.colNames.id !== ""
                            }
                            helperText={
                              !data.colNames.id.startsWith("GCST") &&
                                data.colNames.id !== ""
                                ? "The ID should start with 'GCST'"
                                : ""
                            }
                          />
                        </Box>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={4} sx={gridItemStyle}>
                    <StatusBox
                      message="Please select the source of your GWAS summary statistics."
                      status={data.sourceStatus}
                    />
                  </Grid>

                  {/* Trait Type */}
                  {data.source !== "" && (
                    <>
                      <Grid item xs={4} sx={gridItemStyle}>
                        <Typography variant="h6">Trait Type:</Typography>
                        <Box sx={{ bgcolor: "grey.300", p: 1, borderRadius: 1 }}>
                          <Typography variant="body2">
                            Information on trait type and sample size can be found via the dataset-specific "View Study" link on the <a href="/data" target="_blank">Queryable Data</a> page.
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={4} sx={gridItemStyle}>
                        <FormControl component="fieldset" sx={{ width: "100%" }}>
                          <RadioGroup
                            row
                            aria-label="traitType"
                            name="type"
                            value={data.type}
                            onChange={(e) =>
                              handleInputChange(index, "type", e.target.value)
                            }
                          >
                            <FormControlLabel
                              value="Binary"
                              control={<Radio />}
                              label="Binary"
                            />
                            <FormControlLabel
                              value="Continuous"
                              control={<Radio />}
                              label="Continuous"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                      <Grid item xs={4} sx={gridItemStyle}>
                        <StatusBox
                          message="Please choose the type of trait you are analyzing."
                          status={data.type === "" ? "required" : "ok"}
                        />
                      </Grid>
                    </>
                  )}

                  {/* Sample Size */}
                  {data.type !== "" && data.source !== "Upload Data" && (
                    <>
                      <Grid item xs={4} sx={gridItemStyle}>
                        <Typography variant="h6">Sample Size:</Typography>
                      </Grid>
                      <Grid item xs={4} sx={gridItemStyle}>
                        {data.type === "Binary" && (
                          <FormControl component="fieldset" sx={{ width: "100%" }}>
                            <RadioGroup
                              row
                              value={data.nType}
                              onChange={(e) =>
                                handleInputChange(index, "nType", e.target.value)
                              }
                            >
                              <FormControlLabel
                                value="ncase"
                                control={<Radio />}
                                label="Ncase & Ncontrol"
                              />
                              <FormControlLabel
                                value="neff"
                                control={<Radio />}
                                label="Neff"
                              />

                            </RadioGroup>
                          </FormControl>
                        )}
                        {data.type === "Binary" && data.nType === "ncase" && (
                          <Grid container spacing={1} sx={{ width: "100%" }}>
                            <Grid item xs={6}>
                              <TextField
                                label="N Case"
                                name="ncase"
                                value={data.colNames.ncase}
                                onChange={(e) =>
                                  handleColNameChange(index, "ncase", e.target.value)
                                }
                                margin="normal"
                                fullWidth
                              // error={
                              //   isNaN(Number(data.colNames.ncase)) &&
                              //   data.colNames.ncase !== ""
                              // }
                              // helperText={
                              //   isNaN(Number(data.colNames.ncase)) &&
                              //     data.colNames.ncase !== ""
                              //     ? "Please enter a number."
                              //     : ""
                              // }
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                label="N Control"
                                name="ncontrol"
                                value={data.colNames.ncontrol}
                                onChange={(e) =>
                                  handleColNameChange(
                                    index,
                                    "ncontrol",
                                    e.target.value
                                  )
                                }
                                margin="normal"
                                fullWidth
                                error={
                                  isNaN(Number(data.colNames.ncontrol)) &&
                                  data.colNames.ncontrol !== ""
                                }
                                helperText={
                                  isNaN(Number(data.colNames.ncontrol)) &&
                                    data.colNames.ncontrol !== ""
                                    ? "Please enter a number."
                                    : ""
                                }
                              />
                            </Grid>
                          </Grid>
                        )}
                        {data.type === "Binary" && data.nType === "neff" && (
                          <Grid item xs={12} sx={{ width: "100%" }}>
                            <TextField
                              label="Neff"
                              name="neff"
                              value={data.colNames.neff}
                              onChange={(e) =>
                                handleColNameChange(index, "neff", e.target.value)
                              }
                              margin="normal"
                              fullWidth
                            // error={
                            //   isNaN(Number(data.colNames.neff)) &&
                            //   data.colNames.neff !== ""
                            // }
                            // helperText={
                            //   isNaN(Number(data.colNames.neff)) &&
                            //     data.colNames.neff !== ""
                            //     ? "Please enter a number."
                            //     : ""
                            // }
                            />
                          </Grid>
                        )}
                        {data.type === "Continuous" && (
                          <Grid item xs={12} sx={{ width: "100%" }}>
                            <TextField
                              label="N"
                              name="n"
                              value={data.colNames.n}
                              onChange={(e) =>
                                handleColNameChange(index, "n", e.target.value)
                              }
                              margin="normal"
                              fullWidth
                            // error={
                            //   isNaN(Number(data.colNames.n)) &&
                            //   data.colNames.n !== ""
                            // }
                            // helperText={
                            //   isNaN(Number(data.colNames.n)) &&
                            //     data.colNames.n !== ""
                            //     ? "Please enter a number."
                            //     : ""
                            // }
                            />
                          </Grid>
                        )}
                      </Grid>
                      <Grid item xs={4} sx={gridItemStyle}>
                        <StatusBox
                          message="Please enter the sample size of your input data."
                          status={data.sampleSizeStatus}
                        />
                      </Grid>
                    </>
                  )}


                  {/* Trait Name */}
                  {data.source !== "" && (
                    <>
                      <Grid item xs={4} sx={gridItemStyle}>
                        <Typography variant="h6" sx={{ mb: 2 }}>
                          Trait Name:
                        </Typography>
                      </Grid>
                      <Grid item xs={4} sx={gridItemStyle}>
                        <TextField
                          name="trait"
                          value={data.name}
                          onChange={(e) =>
                            handleInputChange(index, "name", e.target.value)
                          }
                          sx={{ width: "100%" }}
                        />
                      </Grid>
                      <Grid item xs={4} sx={gridItemStyle}>
                        <StatusBox
                          message="Please enter the name of your trait."
                          status={data.name === "" ? "required" : "ok"}
                        />
                      </Grid>
                    </>
                  )}

                  {/* Population */}
                  {data.source !== "" && (
                    <>
                      <Grid item xs={4} sx={gridItemStyle}>
                        <Typography variant="h6" sx={{ mb: 2 }}>
                          Population:
                        </Typography>
                        <Box sx={{ bgcolor: "grey.300", p: 1, borderRadius: 1 }}>
                          <Typography variant="body2">
                            We support analyses on one of the five <a href="https://useast.ensembl.org/Help/View?id=171">super populations</a> defined by the 1000 Genomes Project.
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={4} sx={gridItemStyle}>
                        <FormControl variant="outlined" fullWidth>
                          <Select
                            value={data.population}
                            onChange={(e) =>
                              handleInputChange(index, "population", e.target.value)
                            }
                          >
                            {getAvailablePopulations(index).map((option) => (
                              <MenuItem key={option} value={option}>
                                {option}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={4} sx={gridItemStyle}>
                        <StatusBox
                          message="Please select the population of your input data."
                          status={data.population === "" ? "required" : "ok"}
                        />
                      </Grid>
                    </>
                  )}

                  {/* Match Column Names */}
                  {data.source !== "Query Data" && data.type !== "" && (
                    <>
                      <Grid item xs={4} sx={gridItemStyle}>
                        <Typography variant="h6" sx={{ mb: 2 }}>
                          Column Names:
                        </Typography>
                        <Box sx={{ bgcolor: "grey.300", p: 1, borderRadius: 1 }}>
                          <Typography variant="body2">
                            Please check <a href="https://pennprs.gitbook.io/pennprs/user-manual/single-ancestry-analysis#step-1.-build-input-gwas-summary-data-file" target="_blank">Tutorial</a> for more information.
                          </Typography>
                        </Box>
                      </Grid>

                      {data.type === "Binary" && (
                        <Grid item xs={4} sx={gridItemStyle}>
                          <Box sx={{ bgcolor: "grey.300", p: 1, borderRadius: 1 }}>
                            <Typography variant="body2">
                              Are the data columns already matched to the following?
                            </Typography>
                            <Typography variant="body2" component="div">
                              <ul>
                                <li>Chr | Chromosome : chr</li>
                                <li>Snp | Snpid | RS | Rsid | Rs_id : snp</li>
                                <li>
                                  A1 | Effect_allele | allele1 | alt_allele | EA : a1
                                </li>
                                <li>
                                  A2 | Allele2 | Ref_allele | Other_allele | NEA : a2
                                </li>
                                <li>Beta: beta</li>
                                <li>ODDS | odds | Odds_ratio </li>
                                <li>
                                  Se | Stderr | Std_Error | Stderr_Beta | SE_Beta : se
                                </li>
                                <li>
                                  P | Pvalue | P_value | Pval | P_val | GC_Pvalue : p
                                </li>
                                <li>
                                  MAF| Effect_allele_frequency | Eaf | FRQ | Maf : maf
                                </li>
                                <li>Neff | N_eff</li>
                                <li>Ncase | N_case</li>
                                <li>Ncontrol | N_control</li>
                              </ul>
                              We will delete the redundant columns that are not listed above.
                            </Typography>
                            <RadioGroup
                              row
                              value={data.columnsMatched}
                              onChange={(e) =>
                                handleColumnsMatchedChange(index, e.target.value)
                              }
                            >
                              <FormControlLabel
                                value="yes"
                                control={<Radio />}
                                label="Yes"
                              />
                              <FormControlLabel
                                value="no"
                                control={<Radio />}
                                label="No"
                              />
                            </RadioGroup>
                          </Box>
                        </Grid>
                      )}
                      {data.type === "Continuous" && (
                        <Grid item xs={4} sx={gridItemStyle}>
                          <Box sx={{ bgcolor: "grey.300", p: 1, borderRadius: 1 }}>
                            <Typography variant="body2">
                              Are the data columns already matched to the following?
                            </Typography>
                            <Typography variant="body2" component="div">
                              <ul>
                                <li>CHR | Chr | chr | Chromosome </li>
                                <li>SNP | snp | Snpid | Snpid_UKB | RS | Rsid | Rs_id</li>
                                <li>A1 | a1 | Effect_allele | allele1 | allele_1 | alt_allele | EA </li>
                                <li>A2 | a2 | Allele2 | Allele_2 | Ref_allele | Other_allele | NEA </li>
                                <li>BETA | Beta | beta</li>
                                <li>SE | se | Stderr | Std_Error | Stderr_Beta | SE_Beta | Stderr_B </li>
                                <li>P | Pvalue | P_value | pvalue | Pval | P_val | GC_Pvalue </li>
                                <li>MAF | Maf | maf | Effect_allele_frequency | Eaf | FRQ | FRQ_U | F_U</li>
                                <li>N | n</li>
                              </ul>
                              We will delete the redundant columns that are not listed above.
                            </Typography>
                            <RadioGroup
                              row
                              value={data.columnsMatched}
                              onChange={(e) => handleColumnsMatchedChange(index, e.target.value)}
                            >
                              <FormControlLabel
                                value="yes"
                                control={<Radio />}
                                label="Yes"
                              />
                              <FormControlLabel
                                value="no"
                                control={<Radio />}
                                label="No"
                              />
                            </RadioGroup>
                          </Box>
                        </Grid>)
                      }

                      <Grid item xs={4} sx={gridItemStyle}>
                        <StatusBox
                          message="Please confirm if your columns are matched to the required format."
                          status={
                            data.columnsMatched === null
                              ? "required"
                              : data.colNamesStatus
                          }
                        />
                      </Grid>
                    </>
                  )}

                  {/* Column Names Matching */}
                  {data.source !== "Query Data" && data.columnsMatched === "no" && (
                    <>
                      <Grid item xs={4} sx={gridItemStyle}>
                        <Box sx={{ bgcolor: "grey.300", p: 1, borderRadius: 1 }}>
                          <Typography variant="body2">
                            <b>Please enter column names that match the required format.</b>
                          </Typography>
                          <Typography variant="body2">
                            Please fill in the boxes for names that do not match with ours. If they match, no action is needed.
                          </Typography>

                          <Typography variant="body2">
                            <br></br> <b>NOTE:</b> Required format for the SNP column: RSID (e.g., rs3094315).
                          </Typography>

                          {data.type === "Binary" && (
                            <Typography variant="body2" component="div">
                              <br></br><br></br>Your data should include either two separate columns for the number of cases and controls (i.e., “Ncase” and “Ncontrol”) or one column for the effective sample size (i.e., “Neff”). Please ensure your data includes these columns and input their column names.
                            </Typography>
                          )}



                        </Box>


                      </Grid>
                      <Grid item xs={4} sx={gridItemStyle}>
                        <FormControl component="fieldset">
                          <form noValidate autoComplete="off">
                            <Grid container spacing={2}>
                              {/* Column name fields */}
                              <Grid item xs={6}>
                                <TextField
                                  label="CHROMOSOME"
                                  name="chr"
                                  value={data.colNames.chr}
                                  onChange={(e) =>
                                    handleColNameChange(
                                      index,
                                      "chr",
                                      e.target.value
                                    )
                                  }
                                  margin="normal"
                                  fullWidth
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <TextField
                                  label="SNP/RSID"
                                  name="snp"
                                  value={data.colNames.snp}
                                  onChange={(e) =>
                                    handleColNameChange(
                                      index,
                                      "snp",
                                      e.target.value
                                    )
                                  }
                                  margin="normal"
                                  fullWidth
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <TextField
                                  label="A1"
                                  name="a1"
                                  value={data.colNames.a1}
                                  onChange={(e) =>
                                    handleColNameChange(
                                      index,
                                      "a1",
                                      e.target.value
                                    )
                                  }
                                  margin="normal"
                                  fullWidth
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <TextField
                                  label="A2"
                                  name="a2"
                                  value={data.colNames.a2}
                                  onChange={(e) =>
                                    handleColNameChange(
                                      index,
                                      "a2",
                                      e.target.value
                                    )
                                  }
                                  margin="normal"
                                  fullWidth
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <TextField
                                  label={data.type === "Binary" ? "BETA/Odds Ratio" : "BETA"}
                                  name="beta"
                                  value={data.colNames.beta}
                                  onChange={(e) =>
                                    handleColNameChange(
                                      index,
                                      "beta",
                                      e.target.value
                                    )
                                  }
                                  margin="normal"
                                  fullWidth
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <TextField
                                  label="SE"
                                  name="se"
                                  value={data.colNames.se}
                                  onChange={(e) =>
                                    handleColNameChange(index, "se", e.target.value)
                                  }
                                  margin="normal"
                                  fullWidth
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <TextField
                                  label="P"
                                  name="p"
                                  value={data.colNames.p}
                                  onChange={(e) =>
                                    handleColNameChange(index, "p", e.target.value)
                                  }
                                  margin="normal"
                                  fullWidth
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <TextField
                                  label="MAF"
                                  name="maf"
                                  value={data.colNames.maf}
                                  onChange={(e) =>
                                    handleColNameChange(index, "maf", e.target.value)
                                  }
                                  margin="normal"
                                  fullWidth
                                />
                              </Grid>
                              <Grid item xs={12}>
                                {data.type === "Binary" && (
                                  <FormControl component="fieldset">
                                    <RadioGroup
                                      row
                                      value={data.nType}
                                      onChange={(e) =>
                                        handleInputChange(index, "nType", e.target.value)
                                      }
                                    >
                                      <FormControlLabel
                                        value="neff"
                                        control={<Radio />}
                                        label="Neff"
                                      />
                                      <FormControlLabel
                                        value="ncase"
                                        control={<Radio />}
                                        label="Ncase & Ncontrol"
                                      />
                                    </RadioGroup>
                                  </FormControl>

                                )}

                                {data.type === "Binary" && data.nType === "ncase" && (
                                  <Grid container spacing={1} sx={{ width: "100%" }}>
                                    <Grid item xs={6}>
                                      <TextField
                                        label="N Case"
                                        name="ncase"
                                        value={data.colNames.ncase}
                                        onChange={(e) =>
                                          handleColNameChange(index, "ncase", e.target.value)
                                        }
                                        margin="normal"
                                        fullWidth
                                      // error={
                                      //   isNaN(Number(data.colNames.ncase)) &&
                                      //   data.colNames.ncase !== ""
                                      // }
                                      // helperText={
                                      //   isNaN(Number(data.colNames.ncase)) &&
                                      //     data.colNames.ncase !== ""
                                      //     ? "Please enter a number."
                                      //     : ""
                                      // }
                                      />
                                    </Grid>
                                    <Grid item xs={6}>
                                      <TextField
                                        label="N Control"
                                        name="ncontrol"
                                        value={data.colNames.ncontrol}
                                        onChange={(e) =>
                                          handleColNameChange(
                                            index,
                                            "ncontrol",
                                            e.target.value
                                          )
                                        }
                                        margin="normal"
                                        fullWidth
                                      // error={
                                      //   isNaN(Number(data.colNames.ncontrol)) &&
                                      //   data.colNames.ncontrol !== ""
                                      // }
                                      // helperText={
                                      //   isNaN(Number(data.colNames.ncontrol)) &&
                                      //     data.colNames.ncontrol !== ""
                                      //     ? "Please enter a number."
                                      //     : ""
                                      // }
                                      />
                                    </Grid>
                                  </Grid>
                                )}
                                {data.type === "Binary" && data.nType === "neff" && (
                                  <Grid item xs={12} sx={{ width: "100%" }}>
                                    <TextField
                                      label="Neff"
                                      name="neff"
                                      value={data.colNames.neff}
                                      onChange={(e) =>
                                        handleColNameChange(index, "neff", e.target.value)
                                      }
                                      margin="normal"
                                      fullWidth
                                    // error={
                                    //   isNaN(Number(data.colNames.neff)) &&
                                    //   data.colNames.neff !== ""
                                    // }
                                    // helperText={
                                    //   isNaN(Number(data.colNames.neff)) &&
                                    //     data.colNames.neff !== ""
                                    //     ? "Please enter a number."
                                    //     : ""
                                    // }
                                    />
                                  </Grid>
                                )}

                                {data.type === "Continuous" && (
                                  <Grid item xs={12} sx={{ width: "100%" }}>
                                    <TextField
                                      label="N"
                                      name="n"
                                      value={data.colNames.n}
                                      onChange={(e) =>
                                        handleColNameChange(index, "n", e.target.value)
                                      }
                                      margin="normal"
                                      fullWidth
                                    // error={
                                    //   isNaN(Number(data.colNames.n)) &&
                                    //   data.colNames.n !== ""
                                    // }
                                    // helperText={
                                    //   isNaN(Number(data.colNames.n)) &&
                                    //     data.colNames.n !== ""
                                    //     ? "Please enter a number."
                                    //     : ""
                                    // }
                                    />
                                  </Grid>
                                )}

                              </Grid>
                            </Grid>
                          </form>
                        </FormControl>
                      </Grid>


                      <Grid item xs={4} sx={gridItemStyle}>
                        <StatusBox
                          message="Entering the column names of your input data is optional but recommended. If not provided, we will automatically detect the column names of your input data."
                          status={data.colNamesStatus}
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>
      ))}

      {/* Save & Continue Button */}
      <Box sx={{ width: "100%", mt: 5 }}>
        <Grid
          container
          spacing={{ xs: 3, md: 6 }}
          columns={{ xs: 12, sm: 12, md: 12 }}
        >
          <Grid item xs={1}></Grid>
          <Grid item xs={4}>
            <Button
              variant="contained"
              type="submit"
              fullWidth
              onClick={() => setStep(0)}
              sx={{
                color: "white",
                backgroundColor: "grey",
                mb: 2,
                mt: -1,
                textTransform: "none",
                fontSize: 18,
                fontWeight: "bold",
                "&:hover": { backgroundColor: "#0d47a1" },
              }}
            >
              Back to Introduction
            </Button>
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={4}>
            <Button
              variant="contained"
              type="submit"
              fullWidth
              onClick={handleSubmit}
              sx={{
                color: "white",
                backgroundColor: "grey",
                mb: 2,
                mt: -1,
                fontSize: 18,
                fontWeight: "bold",
                "&:hover": { backgroundColor: "#0d47a1" },
              }}
            >
              Save & Continue
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};